function getWindow() {
    return window;
}

window.getData = function() {

    var data = {};

    if (!getWindow()._fields) {
        return data;
    }

    for (var i = 0; i < getWindow()._fields.length; i++) {
        var field = getWindow()._fields[i];

        if (!field) {
            continue;
        }

        if (field.variable == 'phone_number') {
            data.phone = field.value.replaceAll(' ', '');
        }

        if (field.variable == 'address_line1') {
            data.address_line1 = field.value;
        }

        if (field.variable == 'address_line2') {
            data.address_line2 = field.value;
        }

        if (field.variable == 'address_city') {
            data.address_city = field.value;
        }

        if (field.variable == 'address_state') {
            data.address_state = field.value;
        }

        if (field.variable == 'address_postal_code') {
            data.address_postal_code = field.value;
        }

        if (field.variable == 'user_first_name') {
            data.firstname = field.value;
        }

        if (field.variable == 'user_last_name') {
            data.lastname = field.value;
        }

        if (field.variable == 'email') {
            data.email = field.value;
        }
        
        if(field.variable == 'dob') {
            data.dob = field.value;
        }
        
        if(field.variable == 'gender') {
            data.gender = field.value;
        }
        
        if (field.variable == 'billing_plan') {
            data.billing_option = parseFloat(field.value).toFixed(2);
        }
        
        if (field.variable == 'billing_plan') {
            data.billing_plan = field.value;
        }

    }

    data.fullname = data.firstname + ' ' + data.lastname;

    return data;

};


function converter(feet, inches) {
    var totalInches = parseInt(feet) * 12;

    return totalInches + parseInt(inches);
}

window.addEventListener('heyflow-change', (event) => {
   window._fields = event.detail.fields; 
   
    // if(event.detail.screenName == 'checkout') {
    //     window.document.querySelector('span[data-variable="billing_plan"]')[0].innerHTML = window.getData().billing_option;
    // }
    
    
});

window.addEventListener('heyflow-screen-view', (event) => {
    
    if(event.detail.screenName == 'set-email') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: "begin_checkout",
            ecommerce: {
                currency: "USD",
                value: 0.00,
                items: [
                    {
                        item_name: "Plan",
                        index: 0,
                        item_brand: "HLTHi",
                        item_category: "Plans",
                        item_list_id: "related_products",
                        item_list_name: "Advance",
                        price: 0.00,
                        quantity: 1
                    }
                ]
            }
        });
    }

    if (event.detail.screenName == 'basic-information') {
        window.document.getElementById('input-ae094607').value = 2;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: "add_email_info",
            ecommerce: {
                currency: "USD",
                value: 0.00,
                items: [
                    {
                        item_name: "Plan",
                        index: 0,
                        item_brand: "HLTHi",
                        item_category: "Plans",
                        item_list_id: "related_products",
                        item_list_name: "Advance",
                        price: 0.00,
                        quantity: 1
                    }
                ]
            }
        });
    }
    
    if (event.detail.screenName == 'choose-pharmcy') {
        window.document.getElementById('input-ae094607').value = 3;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: "add_pharmacy_info",
            ecommerce: {
                currency: "USD",
                value: 0.00,
                items: [
                    {
                        item_name: "Plan",
                        index: 0,
                        item_brand: "HLTHi",
                        item_category: "Plans",
                        item_list_id: "related_products",
                        item_list_name: "Advance",
                        price: 0.00,
                        quantity: 1
                    }
                ]
            }
        });
    }
    
    if (event.detail.screenName == 'shipping-information') {
        window.document.getElementById('input-ae094607').value = 4;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: "add_contact_info",
            ecommerce: {
                currency: "USD",
                value: 0.00,
                items: [
                    {
                        item_name: "Plan",
                        index: 0,
                        item_brand: "HLTHi",
                        item_category: "Plans",
                        item_list_id: "related_products",
                        item_list_name: "Advance",
                        price: 0.00,
                        quantity: 1
                    }
                ]
            }
        });
    }
    
    if (event.detail.screenName == 'checkout') {
        
        if(window.getData().address_state) {
            
            var states = ['FL', 'AL'];
            if(!states.includes(window.getData().address_state)) {
                
                // Inject Error
                
                var errorHTML = `<div class="block  preview-highlight preview-clicked" style=" " data-blocktype="elements-v2">
	
	<div class="block-content elements-container elements-v2-container inner-full" data-type="nested-blocks">
	  <div class="inner-full container-style-4" style="margin-top:10px; margin-right:10px; margin-bottom:10px; margin-left:10px; padding-top:10px; padding-right:10px; padding-bottom:10px; padding-left:10px; ">
	    <div class="elements-container-inner inner-wide" style="justify-content:normal;align-items:center;flex-direction:row;margin-left:auto;margin-right:auto;">
	        <div class="block  " style=" " data-blocktype="rich-text">
	        		<div class="block-type-indicator">rich-headline</div>
	        
	        	<div class="block-content text-block align-center inner-mid" id="text-block-headline-c197546d">
	        		<div class="rich-text" style="padding-top:20px; padding-bottom:20px; ">
	        			<h5>We are currently not accepting new patients in your state.</h5>
	        	<h6>Currently only accepting patients in Florida, and Alabama.&nbsp;</h6>
	        		</div>
	        	</div></div>    </div>
	  </div>
	</div></div>`;
	
	window.document.getElementById('id-0c105644').insertAdjacentHTML("afterbegin",errorHTML);
                
            }
        }
        
        window.document.getElementById('input-ae094607').value = 5;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: "add_payment_info",
            ecommerce: {
                currency: "USD",
                value: 0.00,
                items: [
                    {
                        item_name: "Plan",
                        index: 0,
                        item_brand: "HLTHi",
                        item_category: "Plans",
                        item_list_id: "related_products",
                        item_list_name: "Advance",
                        price: 0.00,
                        quantity: 1
                    }
                ]
            }
        });
    }
    
});



function generateParams(baseUrl, params) {
    
    // Create a new URL object from the base URL
    const url = new URL(baseUrl);
    
    // Loop through the keys of the params object
    for (const [key, value] of Object.entries(params)) {
        // Add each key-value pair as a query parameter
        url.searchParams.append(key, value);
    }
    
    // Return the full URL as a string
    return url.href;
}

// Function to replace existing event listeners and add a new one
function resetEventListener(buttonSelector, newEvent, newListener) {
    // Select the button element
    const button = document.querySelector(buttonSelector);
    
    if (button) {
        // Clone the button without any event listeners
        const newButton = button.cloneNode(true);
        
        // Replace the original button with the clone
        button.parentNode.replaceChild(newButton, button);
        
        // Add the new event listener to the cloned button
        newButton.addEventListener(newEvent, newListener);
    }
}

window.addEventListener('heyflow-visit', (event) => {
    
});

window.addEventListener('heyflow-screen-view', (event) => {
    
    if (event.detail.screenName == 'checkout') {

        initialize(window.getData());

        return;
    }
    
    if(event.detail.screenName == 'choose-treatment') {
        var data = window.getData();
        
        // Urgent Care Button
        var urgentCareUrl = generateParams("https://start.hlthi.life/hlthi-patient-intake", {
            fname: data.firstname,
            lname: data.lastname,
            postal: data.address_postal_code,
            state: data.address_state,
            city: data.address_city,
            address2: data.address_line2,
            address1: data.address_line1,
            phone: data.phone,
            dob: data.dob,
            gender: data.gender == 'Male' ? 1 : 0,
            email: data.email
        });
        
        var urgentCareBtn = window.document.getElementById('urgent-care-link');
        
        urgentCareBtn.setAttribute('href', urgentCareUrl);
        
        var weightLossBtn = window.document.getElementById('weight-loss-link');
        weightLossBtn.setAttribute('href', generateParams("https://start.hlthi.life/hlthi-patient-intake", {
                fname: data.firstname,
                lname: data.lastname,
                postal: data.address_postal_code,
                state: data.address_state,
                city: data.address_city,
                address2: data.address_line2,
                address1: data.address_line1,
                phone: data.phone,
                dob: data.dob,
                gender: data.gender == 'Male' ? 1 : 0,
                email: data.email
            }));
            
        var mentalHealthBtn = window.document.getElementById('mental-health-link');
        
        mentalHealthBtn.setAttribute('href', generateParams("https://start.hlthi.life/mental-health", {
                fname: data.firstname,
                lname: data.lastname,
                postal: data.address_postal_code,
                state: data.address_state,
                city: data.address_city,
                address2: data.address_line2,
                address1: data.address_line1,
                phone: data.phone,
                dob: data.dob,
                gender: data.gender == 'Male' ? 1 : 0,
                email: data.email
            }));
        
        var rxRefill = window.document.getElementById('rx-refill-link');
        
        rxRefill.setAttribute('href', generateParams("https://start.hlthi.life/express-visit", {
                fname: data.firstname,
                lname: data.lastname,
                postal: data.address_postal_code,
                state: data.address_state,
                city: data.address_city,
                address2: data.address_line2,
                address1: data.address_line1,
                phone: data.phone,
                dob: data.dob,
                gender: data.gender == 'Male' ? 1 : 0,
                email: data.email
            }) + '#questions-evaluation');
        
    }

});

function getCardElement() {
    return window.cardElement;
}

window.addEventListener('heyflow-submit', (event) => {

    

});

async function initMap() {
    // Request needed libraries.
    
    if(typeof google === 'undefined') {
        return
    }
    
    //@ts-ignore
    const [{
        Map
    }] = await Promise.all([
        google.maps.importLibrary("places")
    ]);

    //@ts-ignore
    const placeAutocomplete = new google.maps.places.PlaceAutocompleteElement({
        types: ['pharmacy'],
      });

    //@ts-ignore
    placeAutocomplete.id = "place-autocomplete-input";

    const card = document.getElementById("place-autocomplete-card");

    //@ts-ignore
    card.appendChild(placeAutocomplete);

    // Add the gmp-placeselect listener, and display the results on the map.
    //@ts-ignore
    placeAutocomplete.addEventListener("gmp-placeselect", async ({
        place
    }) => {
        await place.fetchFields({
            fields: ["displayName", "formattedAddress", "location","nationalPhoneNumber"],
        });

        updateInfoWindow(place);

    });
}

// Helper function to create an info window.
function updateInfoWindow(data) {
   document.getElementById('textarea-05708e2f').value = data.displayName + " \n " + data.formattedAddress + " \n " + data.nationalPhoneNumber;
}

try {
    initMap();
} catch(e) {
    console.log("Could not load map.");
}
